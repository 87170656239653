/* eslint-disable react/prop-types */

import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { type AppType } from "next/app";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Footer } from "~/components/Footer";
import Navbar from "~/components/Navbar";
import "~/styles/globals.css";
import { api } from "~/utils/api";

const MyApp: AppType<{ session: Session | null }> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  return (
    <>
      <SessionProvider session={session}>
        <div className="prose mx-auto">
          <Navbar />
          <Component {...pageProps} />
        </div>
        <Footer />
      </SessionProvider>
      <Analytics />
      <SpeedInsights />
    </>
  );
};

export default api.withTRPC(MyApp);
