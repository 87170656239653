import Link from "next/link";

export const Footer = () => (
  <footer className="footer mt-7 bg-neutral p-10 text-neutral-content">
    {/* <nav>
      <h6 className="footer-title">Services</h6>
      <Link href="/books" className="link-hover link">
        Books
      </Link>
    </nav> */}
    <nav>
      <h6 className="footer-title">The Library</h6>
      <Link href="/about" className="link-hover link">
        About us
      </Link>
      <Link href="/contact" className="link-hover link">
        Contact
      </Link>
    </nav>
    <nav>
      <h6 className="footer-title">Legal</h6>
      <Link href="/policies" className="link-hover link">
        Policies
      </Link>
    </nav>

    <nav>
      <h6 className="footer-title">Copyright</h6>
      <p>
        ©{new Date().getFullYear()} Imam Ibn {`al-Qayyim's`} Islamic Public
        Library
      </p>
      <p>All Rights Reserved</p>
    </nav>
  </footer>
);
