import { signOut, useSession } from "next-auth/react";
import Link from "next/link";
import { useCallback } from "react";
import { env } from "~/env.mjs";
import { api } from "~/utils/api";

export default function Navbar() {
  return (
    <div className="navbar bg-base-100">
      <div className="navbar bg-base-100">
        <div className="navbar-start">
          <MainDropDownMenu />
        </div>

        <div className="navbar-center">
          <Link href="/" className="btn-ghost btn text-xl">
            IPLNJ
          </Link>
        </div>

        <div className="navbar-end">
          <AuthNavBarItem />
        </div>
      </div>
    </div>
  );
}

const AuthNavBarItem = () => {
  const { data: sessionData } = useSession();

  const handleSignOutClick = useCallback(async () => {
    const message = "Are you sure you want to sign out?";
    if (!window.confirm(message)) return;
    await signOut();
  }, []);

  return (
    <div className="dropdown-end dropdown">
      <div tabIndex={0} role="button" className="btn-ghost btn-circle btn">
        <UserIcon />
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content menu rounded-box z-[1] w-52 bg-base-100 p-2 shadow"
        onClick={() =>
          setTimeout(() => (document.activeElement as HTMLElement).blur(), 150)
        }
      >
        {sessionData?.user ? (
          <>
            <li>
              <Link className="no-underline" href="/my-account">
                My account
              </Link>
            </li>
            <li>
              <a className="no-underline" onClick={handleSignOutClick}>
                Sign out
              </a>
            </li>
            <DeleteSquareProfile />
          </>
        ) : (
          <>
            <li>
              <Link className="no-underline" href="/auth/signin">
                Sign in
              </Link>
            </li>
            <li>
              <Link className="no-underline" href="/auth/signup">
                Sign up
              </Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

const MainDropDownMenu = () => {
  const close = useCallback(
    () => setTimeout(() => (document.activeElement as HTMLElement).blur(), 150),
    []
  );

  return (
    <div className="dropdown">
      <div tabIndex={0} role="button" className="btn-ghost btn-circle btn">
        <HamburgerIcon />
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content menu rounded-box z-[1] mt-3 w-52 bg-base-100 p-2 no-underline shadow"
        onClick={close}
      >
        <li>
          <Link className="no-underline" href="/">
            Home
          </Link>
        </li>
        <li>
          <Link className="no-underline" href="/donate">
            Donate
          </Link>
        </li>
        <li>
          <Link className="no-underline" href="/plans">
            Membership
          </Link>
        </li>
        <li>
          <Link className="no-underline" href="/about">
            About Us
          </Link>
        </li>
        <li>
          <Link className="no-underline" href="/contact">
            Contact
          </Link>
        </li>
      </ul>
    </div>
  );
};

const UserIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 8a4 4 0 11-8 0 4 4 0 018 0zM12 14s-8 1-8 5v3h16v-3c0-4-8-5-8-5z"
    />
  </svg>
);

const HamburgerIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 6h16M4 12h8m-8 6h16"
    />
  </svg>
);

const DeleteSquareProfile = () => {
  const { mutateAsync, error } = api.user.deleteSquareProfile.useMutation();

  const handleClick = useCallback(async () => {
    const message = "Are you sure you want to delete your Square profile?";
    if (!window.confirm(message)) return;
    window.alert(JSON.stringify(await mutateAsync()));
  }, [mutateAsync]);

  if (env.NEXT_PUBLIC_ALLOW_DELETE_SQUARE_PROFILE !== "true") return null;

  return (
    <li>
      <a onClick={handleClick} className="no-underline">
        (DEV only) Delete <br /> Square Profile
      </a>
      {error && <p className="text-red-500">{error.message}</p>}
    </li>
  );
};
